import { HOST } from 'utils/network';

export const vinyl = {
  name: 'vinyl',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'kick',
      path: HOST + '/kits/vinyl/kick.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick',
    },
    {
      name: 'kick 2',
      path: HOST + '/kits/vinyl/kick2.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'kick2',
    },
    {
      name: 'snare',
      path: HOST + '/kits/vinyl/snr.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'snr',
    },
    {
      name: 'closed hat',
      path: HOST + '/kits/vinyl/ch.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'ch',
    },
    {
      name: 'open hat',
      path: HOST + '/kits/vinyl/oh.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'oh',
    },
    {
      name: 'clap',
      path: HOST + '/kits/vinyl/clp.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'clp',
    },
    {
      name: 'vocal',
      path: HOST + '/kits/vinyl/voc.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'voc',
    },
    {
      name: 'shaker',
      path: HOST + '/kits/vinyl/shk.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'shk',
    },
    {
      name: 'rim',
      path: HOST + '/kits/vinyl/rim.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'sticks',
    },
  ],
};

export const saffron = {
  name: 'saffron',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'stomp',
      path: HOST + '/kits/saffron/stomp.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick',
    },
    {
      name: 'tone',
      path: HOST + '/kits/saffron/urdu_tone.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'kick2',
    },
    {
      name: 'smack',
      path: HOST + '/kits/saffron/urdu_smack.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'snr',
    },
    {
      name: 'clap',
      path: HOST + '/kits/saffron/clp.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'clp',
    },
    {
      name: 'block',
      path: HOST + '/kits/saffron/plop.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'wb',
    },
    {
      name: 'tamb',
      path: HOST + '/kits/saffron/tamb.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'tamb',
    },
    {
      name: 'vocal',
      path: HOST + '/kits/saffron/voc.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'voc',
    },
    {
      name: 'chimes',
      path: HOST + '/kits/saffron/chimes.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'cym',
    },
    {
      name: 'bells',
      path: HOST + '/kits/saffron/bells.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'fx',
    },
  ],
};

export const punchy = {
  name: 'punchy',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'kick',
      path: HOST + '/kits/punchy/kick.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick',
    },
    {
      name: 'snare',
      path: HOST + '/kits/punchy/snr.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'snr',
    },
    {
      name: 'rim',
      path: HOST + '/kits/punchy/rim.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'wb',
    },
    {
      name: 'closed hat',
      path: HOST + '/kits/punchy/ch.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'ch',
    },
    {
      name: 'open hat',
      path: HOST + '/kits/punchy/oh.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'oh',
    },
    {
      name: 'cymbal',
      path: HOST + '/kits/punchy/cym.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'cym',
    },
    {
      name: 'high tom',
      path: HOST + '/kits/punchy/ht.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'tom',
    },
    {
      name: 'mid tom',
      path: HOST + '/kits/punchy/mt.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'tom',
    },
    {
      name: 'low tom',
      path: HOST + '/kits/punchy/lt.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'tom',
    },
  ],
};

export const sauce = {
  name: 'sauce',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'kick',
      path: HOST + '/kits/sauce/kick.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick',
    },
    {
      name: 'snare',
      path: HOST + '/kits/sauce/snr.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'snr',
    },
    {
      name: 'snap',
      path: HOST + '/kits/sauce/snp.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'fx',
    },
    {
      name: 'hat 1',
      path: HOST + '/kits/sauce/ch.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'ch',
    },
    {
      name: 'hat 2',
      path: HOST + '/kits/sauce/ch2.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'ch',
    },
    {
      name: 'hat 3',
      path: HOST + '/kits/sauce/ch3.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'ch',
    },
    {
      name: 'plop',
      path: HOST + '/kits/sauce/plop.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'wb',
    },
    {
      name: 'vocal 1',
      path: HOST + '/kits/sauce/voc1.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'voc',
    },
    {
      name: 'vocal 2',
      path: HOST + '/kits/sauce/voc2.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'voc',
    },
  ],
};

export const electric = {
  name: 'electric',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'kick',
      path: HOST + '/kits/electric/kick.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick',
    },
    {
      name: 'fat snare',
      path: HOST + '/kits/electric/fatsnr.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'snr',
    },
    {
      name: 'big snare',
      path: HOST + '/kits/electric/bigsnr.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'snr2',
    },
    {
      name: 'hat 1',
      path: HOST + '/kits/electric/oh.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'oh',
    },
    {
      name: 'hat 2',
      path: HOST + '/kits/electric/oh2.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'oh',
    },
    {
      name: 'clap',
      path: HOST + '/kits/electric/clp.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'clp',
    },
    {
      name: 'tom',
      path: HOST + '/kits/electric/tom.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'tom',
    },
    {
      name: 'tone',
      path: HOST + '/kits/electric/tone.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'note',
    },
    {
      name: 'vocal',
      path: HOST + '/kits/electric/voc.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'fx',
    },
  ],
};

export const beatbox = {
  name: 'beatbox',
  location: 'remote',
  available: false,
  samples: [
    {
      name: 'kick tone',
      path: HOST + '/kits/beatbox/kick_tone.mp3',
      code: 'Numpad7',
      altCode: 'KeyQ',
      color: 0,
      icon: 'kick2',
    },
    {
      name: 'kick',
      path: HOST + '/kits/beatbox/kick.mp3',
      code: 'Numpad8',
      altCode: 'KeyW',
      color: 1,
      icon: 'kick',
    },
    {
      name: 'snare',
      path: HOST + '/kits/beatbox/snr.mp3',
      code: 'Numpad9',
      altCode: 'KeyE',
      color: 2,
      icon: 'snr',
    },
    {
      name: 'hat',
      path: HOST + '/kits/beatbox/ch.mp3',
      code: 'Numpad4',
      altCode: 'KeyA',
      color: 3,
      icon: 'ch',
    },
    {
      name: 'plop',
      path: HOST + '/kits/beatbox/plop.mp3',
      code: 'Numpad5',
      altCode: 'KeyS',
      color: 4,
      icon: 'wb',
    },
    {
      name: 'cym',
      path: HOST + '/kits/beatbox/cym.mp3',
      code: 'Numpad6',
      altCode: 'KeyD',
      color: 5,
      icon: 'cym',
    },
    {
      name: 'breath',
      path: HOST + '/kits/beatbox/breath.mp3',
      code: 'Numpad1',
      altCode: 'KeyZ',
      color: 6,
      icon: 'fx',
    },
    {
      name: 'breath2',
      path: HOST + '/kits/beatbox/breath2.mp3',
      code: 'Numpad2',
      altCode: 'KeyX',
      color: 7,
      icon: 'fx',
    },
    {
      name: 'scratch',
      path: HOST + '/kits/beatbox/scratch.mp3',
      code: 'Numpad3',
      altCode: 'KeyC',
      color: 8,
      icon: 'fx',
    },
  ],
};
